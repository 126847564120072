<template>
  <van-nav-bar title="学生列表" left-arrow @click-left="onClickLeft"></van-nav-bar>
  <div class="student" style="margin: 10px 15px 10px">
    <van-list
      v-model:loading="loading"
      :finished="finished"
      finished-text=""
      @load="onLoad"
    >
      <div class="container" v-for="item in list" :key="item">
        <div class="student_info" @click="toPayment(item)">
          <div class="info">
            <img src="../assets/default_profile.png" />
            <div class="student_desc">
              <!-- <span>{{ safeName(item.name) }}</span> -->
              <span>{{ item.name }}</span>
              <span>{{ item.school_name }}</span>
              <span>{{ item.class_name ? item.class_name : "未加入班级" }}</span>
            </div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { ref } from "vue";
import { Toast } from "vant";
import { useRouter } from "vue-router";
import {  safeName } from "../common/js/utils";
import { setStorage,getStorage } from "../common/js/storeUtil";

export default {
  setup() {
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const router = useRouter(); //路由传值

    () => {
      /* eslint-disable */
      // iCityBridge.setTitle("学生列表");
    };

    // let store = useStore();
    const onClickLeft = () => {
      router.go(-1);
    };

    const onLoad = () => {
      // api
      //   .getChildren()
      //   .then((res) => {
      //     if (res.data.code == 12000) {
      //       var childList = res.data.result;
      //       // console.log("请求->", childList);
      //       for (let i = 0; i < childList.length; i++) {
      //         list.value.push(childList[i]);
      //       }
      //     } else {
      //       Toast(res.data.message);
      //     }
      //   })
      //   .catch((err) => {
      //     Toast(err.message);
      //     // console.log("请求->", err.m);
      //   });
      list.value = getStorage("students");

      // 加载状态结束
      loading.value = false;
      // 数据全部加载完成
      finished.value = true;
    };

    function toPayment(item) {
      setStorage("student", item);
      router.replace({
        path: "/payment",
        // query: {
        //   child_id: item.child_id,
        //   child_name: item.real_name,
        //   school_name: item.school_name,
        //   class_name: item.class_name ? item.class_name : "未加入班级",
        // },
      });
    }

    return {
      list,
      onLoad,
      loading,
      finished,
      onClickLeft,
      toPayment,
      safeName,
    };
  },
};
</script>

<style lang="less" scoped>
.student {
  min-height: 500px;
  .container {
    height: 140px;
    .student_info {
      width: 94%;
      margin: 0 auto;
      height: 120px;
      background: linear-gradient(to right, #3794fc, #82BAFB);
      box-shadow: 0 2px 5px #3486a7;
      border-radius: 6px;

      .info {
        display: block;
        width: 92%;
        height: 120px;
        margin: 0 auto;
        .changeBtn {
          float: right;
          margin-top: 20px;
          margin-right: 10px;
        }

        img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          margin-top: 4px;
          display: inline-block;
          overflow: hidden;
          margin-top: 30px;
        }
        .student_desc {
          display: inline-block;
          margin-left: 10px;
          line-height: 20px;
          font-size: 14px;
          color: #fff;
          span {
            display: block;
            color: #fff;
            font-size: 14px;
            padding: 2px 0;
            text-align: left;
          }
        }
      }
    }
  }
}
</style>
